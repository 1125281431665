import Polaroid from "../../components/polaroid/polaroid"

export default function People() {
  return (
    <div className="information-content">
      <div className="information-content-title">My love of People</div>
      <div className="information-content-text">
        At my core, the most important thing to me is people. Other human
        beings. That's me at my core when you strip everything else back. The
        only reason I ever did anything (e.g., learning languages, dancing,
        improv) is to experience a deep sense of genuine human connection.
        <br /> <br />
        At one point, I even made the decision to pick between people and maths
        - and ultimately, I chose people. I didn't want to become an academic
        because it just didn't satisfy my need for connection.
        <Polaroid
          active={false}
          image="https://cdn.midjourney.com/76bb474b-1da9-49bc-9d8d-02c44b767a90/0_2.png"
          caption="AI Artist's rendition of choosing between people and logic"
          width="70%"
        />
        <br /> <br />
        WIth all that extraversion, I should say - if you ever want to reach
        out, you can find me{" "}
        <a href="https://www.instagram.com/amiraclehamza/">here</a>.
      </div>
    </div>
  )
}
