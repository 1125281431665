import Polaroid from "../../components/polaroid/polaroid"
import benimprov from "../../assets/hobbies/benimprov.jpg"
import improv from "../../assets/hobbies/improv.jpg"

export default function Improv() {
  return (
    <div className="information-content">
      <div className="information-content-title">
        Improv: The show must go on!
      </div>
      <div className="information-content-text">
        Near the beginning of university (05/10/2016), I made a short list of
        every single club and society that I may be interested in joining. Then,
        I tried all of them and narrowed it down further. One of the final ones
        I was left with was 'improvised theatre'. I had not done any drama or
        theatre activities prior to this point … and after some time I loved it.
        <br /> <br />
        <Polaroid
          active={true}
          image={benimprov}
          caption={`Amir and Ben rehearse in 2017`}
          width="70%"
          onClick={function (): void {
            throw new Error("Function not implemented.")
          }}
        />
        <br /> <br />
        My time doing improv started small with local performances on campus and
        continued with runs at the Edinburgh fringe for multiple years (both
        with the university and adult am-dram groups). Despite saying I never
        wanted any responsibility, I ended up as president of Improv at Warwick
        2019-2020.
        <br />
        <br />
        <Polaroid
          active={true}
          image={improv}
          caption={`Selfie taken infront of crowd after show`}
          width="70%"
          onClick={function (): void {
            throw new Error("Function not implemented.")
          }}
        />
        <br /> <br />I love the freedom in Improv to just make up anything and
        really connect with the people on stage. It taps into another beautiful
        form of human connection. It's also quite nice to make a joke and have
        people laugh at it
      </div>
    </div>
  )
}
