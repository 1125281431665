import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import Homepage from "./homepage"

export default function Loading() {
  const nav = useNavigate()

  function onClick() {
    nav("/home")
  }

  const a = <Homepage />

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="loading-wrapper" onClick={onClick}>
        <div style={{ scale: "3 3" }}>
          <div className="lds-heart">
            <div></div>
          </div>
        </div>
        <div
          style={{
            display: "block",
            fontSize: "3rem",
            marginTop: "6rem",
            textAlign: "center",
            lineHeight: "3rem",
          }}
        >
          Open Amir's WOW project
        </div>
      </div>
    </motion.div>
  )
}
