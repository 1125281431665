import Polaroid from "../../components/polaroid/polaroid"

export default function Future() {
  return (
    <div className="information-content">
      <div className="information-content-title">L'avenir</div>
      <div className="information-content-text">
        There's a great scene in Avatar: The last airbender (one of my favourite
        all time series) where Uncle Iroh is asked if he'd like his future read.
        He replies that it remains better as a mystery. I agree - there's
        something to be said for the serendipity of the world. <br /> <br />
        To leave you with something, here's what an AI imagined:
        <br /> <br />
        <Polaroid
          active={false}
          image="https://cdn.midjourney.com/68d665a6-4664-4daa-a563-5e83571f3c2b/0_0.png"
          caption={`Marriage`}
          width="70%"
          onClick={() => {}}
        />
        <br /> <br />
        <Polaroid
          active={false}
          image=" https://cdn.midjourney.com/2caae91a-c5cd-4679-bf98-31b44f263b94/0_0_384_N.webp"
          caption={`First child`}
          width="70%"
          onClick={() => {}}
        />
        <br /> <br />
        <Polaroid
          active={false}
          image=" https://cdn.midjourney.com/93aed1d9-548b-490d-9b35-25dc8364f358/0_2.png"
          caption={`Elderly`}
          width="70%"
          onClick={() => {}}
        />
        <br /> <br />I am not too sure what to make of these possible life
        events... but they definitely make me feel lots of emotions. I wonder
        ...
        <br /> <br /> Are we being sold on fantasies of future happiness (e.g.,
        the conception of finding a life partner ("the one"))?
        <br /> <br />
        Should we remind ourselves of death regularly?
        <br /> <br />
        Do I want to bring children into this world? Or adopt? Or live a rather
        cushier life without?
      </div>
    </div>
  )
}
