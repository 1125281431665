import Polaroid from "../../components/polaroid/polaroid"

export default function Depression() {
  return (
    <div className="information-content">
      <div className="information-content-title">Darkest times</div>
      <div className="information-content-text">
        One of the darkest times in my life was during COVID-19. I want to lead
        you on the journey that I took and how I got there.
        <br /> <br />
        It all began suddenly when world was shutting down and lockdowns were
        being imposed. I had just decided to reject my <i>“dream”</i> job at
        McKinsey to pursue a start-up with my best friend instead. A lot of
        people called me crazy, but I hated working there and I was passionate
        about Education Equation.
        <br /> <br />
        <Polaroid
          active
          caption={"Education Equation logo"}
          image={
            "https://yt3.googleusercontent.com/ytc/AGIKgqOy7dPmaUdmO6JxBUunO4ClsbJuGWhSfFKmCrNR=s900-c-k-c0x00ffffff-no-rj"
          }
          onClick={() => {}}
          width="60%"
        />
        <br />
        <br />
        Our mission was to revolutionise education for millions of students
        worldwide, which was nobel. This is where my first mistake happened. In
        search of success and start-up glory, I neglected my own happiness and
        needs. I was working for 12-14 hours every single day with few or little
        breaks. I had convinced myself that I needed to do this in order to be
        successful and happy.
        <br /> <br />
        The second dilemma was more insidious and worrisome; as I worked long
        days, I hadn't quite noticed how the lack of social interaction was
        affecting me. Normally, check-ins with friends would let me know how I
        was really doing and allow me to become more grounded. Now, all of that
        was gone. My life's natural checks and balances were out of whack!
        <br /> <br />
        I've always considered myself to be extremely happy and hadn't ever
        really thought about mental health. It was during these moments, when
        the start-up was not progressing and I had nothing to show for my
        labour, that I hit a spiral. Things began to take a turn for the worst.
        <br /> <br />
        <Polaroid
          active
          caption={"Burn out"}
          image={
            "https://cdn.midjourney.com/9789a95b-56b3-45d0-ae81-8348aeb3b045/0_2.png"
          }
          onClick={() => {}}
          width="60%"
        />
        <br /> <br />
        Nothing seemed to be working - no amount of journaling, meditating,
        creating a plan, counselling or anything else made the world seem happy.
        It was as if the colour was sucked out of the universe. My most
        fundamental beliefs - that humanity and people are good - were beginning
        to be questioned. I used to always give to homeless people on the street
        from time to time … I stopped caring and focused inward. Bitter and sad.
        <br /> <br />
        I fell out with my best friend and cofounder. I didn't realise that I
        was often taking my vitriol and hatred for the world out on some of the
        people around it.
        <br /> <br />
        During this tumultuous period, I said no to McKinsey, left my start-up,
        took time to reflect on life, rejoined my start-up, raised funding for
        my start-up, taught myself the latest coding skills and fell out with my
        best friend. I was pretty miserable for most of this.
        <br /> <br />
        <Polaroid
          active
          caption={"A bleak outlook"}
          image={
            "https://cdn.midjourney.com/cebd75e5-a8b5-453e-adb2-24da1ac882e6/0_2.png"
          }
          onClick={() => {}}
          width="60%"
        />
        <br /> <br />
        I'd love to say it was some glorious mindset shift that made me happier,
        but it wasn't. Lockdown just ended and COVID was less of a threat. I
        just slowly became happier because I could see my friends. Nevertheless,
        the experience had definitely left its scars - I felt like a shell of a
        man.
        <br /> <br />
        It's then when I met my coach and current therapist; I was able to begin
        to repair and heal. This transformational journey has taken me to
        heights and introspection that I never thought I would. Today, I am an
        international executive coach myself helping to transform the lives of
        others. I'm a founding member of ReHumanity, with the mission to
        globally revolutionise mental health in ways never before seen. You can
        find out more <a href="https://rehumanity.com">here</a>.
      </div>
    </div>
  )
}
