import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"
import CustomisedModal from "../components/modal/CustomisedModal"
import Polaroid, { PolaroidProps } from "../components/polaroid/polaroid"
import PolaroidGroup from "../components/polaroid/polaroid-group"
import pages from "../utils/pages"

function Homepage() {
  //PAINT IMAGES IN A CIRCLE
  const images = pages.map((p) => {
    return p.image_url
  })

  const titles = pages.map((p) => {
    return p.title
  })

  const imagesAsHTML = images.map((image, count) => {
    return (
      <div
        style={{
          width: count == 0 || count == 3 ? "25%" : "25%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <motion.img
          drag
          dragConstraints={{
            top: -5,
            left: -5,
            right: 5,
            bottom: 5,
          }}
          whileHover={{
            scale: 1.5,
            transition: { duration: 0.5 },
          }}
          src={image}
          className="homepage-image"
          onClick={() => {
            setModalState(count + 1)
          }}
        />
        <div style={{ textAlign: "center", fontSize: "2em", padding: "0.5em" }}>
          {titles[count]}
        </div>
      </div>
    )
  })

  // const imagesAsHTML = images.map((image, count) => {
  //   const height = window.innerHeight - 300
  //   const width = window.innerWidth - 300
  //   const theta = ((2 * Math.PI) / images.length) * count
  //   const r = Math.min(height / 2, width / 2)
  //   const r_x = width / 2
  //   const r_y = height / 2
  //   const x = r * Math.cos(theta)
  //   const y = r * Math.sin(theta)

  //   return (
  // <div
  //   style={{
  //     left: `${x + r_x + 75}px`,
  //     top: `${y + r_y + 75}px`,
  //     position: "absolute",
  //   }}
  // >
  //   <motion.img
  //     drag
  //     dragConstraints={{
  //       top: -5,
  //       left: -5,
  //       right: 5,
  //       bottom: 5,
  //     }}
  //     whileHover={{
  //       scale: 1.5,
  //       transition: { duration: 0.5 },
  //     }}
  //     src={image}
  //     className="homepage-image"
  //     onClick={() => {
  //       setModalState(count + 1)
  //     }}
  //   />
  //   <div
  //     style={{ textAlign: "center", fontSize: "1.2em", padding: "0.5em" }}
  //   >
  //     {titles[count]}
  //   </div>
  // </div>
  //   )
  // })

  //LOAD MODALS

  const [modalState, setModalState] = useState(0)
  const [polaroidState, setPolaroidState] = useState(0)

  //NO MODALS OPEN CASE
  if (modalState == 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="homepage-hero-image">
          <div className="homepage-container">
            <div className="homepage-image-container">{imagesAsHTML}</div>
          </div>
        </div>
      </motion.div>
    )
  }

  //LOAD DATA ON CURRENT MODAL
  const polaroidGroupData = pages[modalState - 1].pageSections.map(
    (section, i) => {
      return {
        caption: section.caption,
        image: section.image_url,
        onClick: () => {
          setPolaroidState(i + 1)
        },
        width: pages[modalState - 1].pageSections.length != 3 ? "60%" : "100%",
      } as PolaroidProps
    }
  )

  return (
    <>
      <div className="homepage-hero-image">
        <div className="homepage-container">
          <div className="homepage-image-container">{imagesAsHTML}</div>
        </div>
      </div>
      <div>
        <CustomisedModal
          isModalOpen={modalState !== 0}
          setModalClosed={() => {
            setModalState(0)
            setPolaroidState(0)
          }}
        >
          <AnimatePresence>
            {polaroidState == 0 && (
              <motion.div
                key={polaroidGroupData.toString()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, zIndex: -10 }}
                exit={{ opacity: 0, zIndex: -10 }}
                transition={{ duration: 2 }}
              >
                <div
                  className="information-content-title"
                  style={{ textAlign: "center" }}
                >
                  Open a memory
                </div>
                <PolaroidGroup polaroids={polaroidGroupData} />
              </motion.div>
            )}

            {polaroidState != 0 && (
              <motion.div
                key={polaroidGroupData.toString()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, zIndex: -10 }}
                exit={{ opacity: 0, zIndex: -10 }}
                transition={{ duration: 2 }}
              >
                {/* <Scroll /> */}
                <div className="information-wrapper">
                  <div className="information-lhs">
                    <Polaroid
                      caption="Go back"
                      image={polaroidGroupData[polaroidState - 1].image}
                      onClick={() => {
                        setPolaroidState(0)
                      }}
                      active={true}
                      width="100%"
                    />
                  </div>
                  <div className="information-rhs">
                    {
                      pages[modalState - 1].pageSections[polaroidState - 1]
                        .content
                    }
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </CustomisedModal>
      </div>
    </>
  )
}

export default Homepage
