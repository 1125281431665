export default function Present() {
  return (
    <div className="information-content">
      <div className="information-content-title">Le Présent</div>
      <div className="information-content-text">
        <div>
          The present moment, as I am making this project; the video below
          filled with my reflections and musings. For those of you wondering,
          I've made this project for <a href="https://sandbox.is/">Sandbox</a>.
          It took a lot of work and I'm super pleased with it :)
          <br /> <br />I used Midjourney AI to create most of the images. Coded
          with ReactJS
        </div>
        <br />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/mja6pRjXZZk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen={true}
        ></iframe>
      </div>
    </div>
  )
}
