import Polaroid from "../../components/polaroid/polaroid"

export default function Dance() {
  return (
    <div className="information-content">
      <div className="information-content-title">Dance: Go with the flow</div>
      <div className="information-content-text">
        Dancing was one of my <i>biggest</i> fears and insecurities growing up.
        I was so afraid of someone making fun of me being <i>too stiff</i> or
        not natural enough. “Just try moving your feet” someone said, as if I
        wasn't already trying.
        <br /> <br />
        13/08/2021 was one of my first Bachata dance lessons in London.
        <br />
        <br />
        There was a moment that I can't quite remember when I stopped trying
        'not to be a bad dancer' and started to enjoy myself. I tried to just
        forget about other people and have fun. I kept going to bachata and
        salsa dance classes (which felt equally stressful as they felt fun;
        constantly asking myself questions like “am I doing this wrong”, “is it
        his left or my left?” and “am I sweating too much”).
        <Polaroid
          active={false}
          image="https://cdn.midjourney.com/44f774d6-316d-4eb3-95b4-ac13372fa947/0_3_384_N.webp"
          caption={`AI Artist rendition of me dancing`}
          width="70%"
          onClick={() => {}}
        />
        <br /> <br />
        These days, I absolutely LOVE dancing - it's one of my favourite things
        to do!
      </div>
    </div>
  )
}
