import old_amir from "../../assets/transformation/old-amir.jpg"
import mid_amir from "../../assets/transformation/mid-amir.jpg"
import new_amir from "../../assets/transformation/new-amir.png"
import om from "../../assets/transformation/om.png"
import PolaroidGroup from "../../components/polaroid/polaroid-group"
import Polaroid from "../../components/polaroid/polaroid"

export default function OM() {
  return (
    <div className="information-content">
      <div className="information-content-title">Operation Madness</div>
      <div className="information-content-text">
        At the end of school, I was fed up of being a 'nerd' and break out of
        any associated stereotypes that people may have of me. I wanted to look
        better, get into fashion and style my hair. Thus began Operation Become
        A Madness. <br /> <br />I realised that people's preconceived notions
        and expectations of how I behave would vanish as I went to university -
        new people with no background on who I was. I convinced myself that I
        could transform (of course, you can transform at any moment, but little
        Amir needed this boost to convince him to do it).
        <br /> <br />
        <div>
          <Polaroid
            caption="Before (2016)"
            image={old_amir}
            onClick={() => {}}
            width="100%"
          />
          <br />
          <Polaroid
            caption="Mid (2017)"
            image={mid_amir}
            onClick={() => {}}
            width="100%"
          />
          <br />
          <Polaroid
            caption="Today (2022)"
            image={new_amir}
            onClick={() => {}}
            width="100%"
          />
        </div>
        <br /> <br />
        So, I got to work and employed our favourite friend - the humble
        spreadsheet, to improve my fashion.
        <br /> <br />
        <img
          src={om}
          className=".information-content-image"
          style={{ maxHeight: "300px" }}
        />
        <br /> <br />
        Whether it was a success is left to the reader as an exercise
        <br /> <br />
        <br /> <br />
        <i>
          <small>
            I will make a small note here: There's more to life than just looks,
            but there is something to be said of being confident in your own
            skin!
          </small>
        </i>
      </div>
    </div>
  )
}
