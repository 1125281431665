import Polaroid from "../../components/polaroid/polaroid"
import Poem1 from "../../assets/transformation/Poem1.png"
import Poem2 from "../../assets/transformation/Poem2.png"
import debatepicture from "../../assets/transformation/debatepicture.png"
import debatevideo from "../../assets/transformation/debatevideo.mp4"

import PolaroidGroup from "../../components/polaroid/polaroid-group"

export default function LogicEmotion() {
  return (
    <div className="information-content">
      <div className="information-content-title">War between two sides</div>
      <div className="information-content-text">
        <Polaroid
          caption={"War between sides"}
          image={
            "https://cdn.midjourney.com/52635973-aa16-4daf-b2b7-2bdaf3d7073e/0_3_384_N.webp"
          }
          onClick={function (): void {
            throw new Error("Function not implemented.")
          }}
          width="60%"
        />
        I want to tell you my story. You see, the way I view it, there is a
        world of logic - a cold, hard and stoic world; in this world,
        productivity is king and success is sovereign. And then, there is the
        world of emotion and feeling; where you feel, things flow and you can
        just be (without needing to be useful).
        <br /> <br />
        Indeed, I was born into the world of logic. I watched documentaries on
        science as a 7 year old and taught myself calculus in my spare time.
        During a year 9 half-term (holiday), I locked myself in my room and
        learned how to code.
        <br /> <br />
        You{" "}
        <a href="https://sourceforge.net/projects/ipoet/">
          can still find the poem generator that I coded
        </a>{" "}
        (the code is very messy, but it's functional!) Here's an example poem
        that it wrote:
        <img
          src={Poem1}
          className="information-content-image"
          style={{ maxWidth: "80%", borderRadius: "10px" }}
        />
        <img
          src={Poem2}
          className="information-content-image"
          style={{ maxWidth: "80%", borderRadius: "10px" }}
        />
        <br />
        <br />
        I also did lots of high school debating growing up, furthering my
        leaning into the world of logic. I was pretty deep into it, going to
        lots of competitions. I managed to dig up an old video of me giving a
        speech (Motion is: This House Would allow soldiers to opt out of war)
        and a photo of the London Debate Challenge 2013
        <Polaroid
          active={true}
          image={debatepicture}
          caption={`Winning LDC 2013`}
          width="70%"
          onClick={() => {}}
        />
        <br /> <br />
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            margin: 0,
            padding: 0,
          }}
        >
          <video
            src={debatevideo}
            controls
            style={{ textAlign: "center" }}
            width="50%"
          />
        </div>
        <br /> <br />
        The world of logic has many advantages; for example, the ability to code
        and make this awesome site! However, I've been slowly discovering over
        the past 7 years or so, the power of the world of emotions.
        <br /> <br />
        To feel. Not to suppress. To be human.
        <br /> <br />
        Going to therapy. Dancing. Feeling. Expressing openly. Loving.
        Meditating. Theatre. All of this has moved me along the logic-emotion
        spectrum.
        <br /> <br />
        <PolaroidGroup
          polaroids={[
            {
              caption: "AI rendition of World of Logic",
              image:
                "https://cdn.midjourney.com/a8522f79-54d8-4be8-b915-39c219fb838e/0_2_384_N.webp",
              onClick: () => {},
              width: "100%",
            },
            {
              caption: "AI rendition of World of Emotions",
              image:
                "https://cdn.midjourney.com/a65219a4-f261-4dbc-b27d-3485dde0ac4e/0_2_384_N.webp",
              onClick: () => {},
              width: "100%",
            },
          ]}
        />
        I am an immigrant in many ways - I have 2 passports; one for the world
        of logic and one for the world of feelings. That's balance.
        <br /> <br />
      </div>
    </div>
  )
}
