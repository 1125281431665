import happy1 from "../../assets/time/happy1.jpg"
import happy2 from "../../assets/time/happy2.jpg"
import baby from "../../assets/time/baby.jpg"
import Polaroid from "../../components/polaroid/polaroid"

export default function Past() {
  return (
    <div className="information-content">
      <div className="information-content-title">Ce qui c'est passé</div>
      <div className="information-content-text">
        Looking back from my current cushy middle class London life, it can be
        easy to forget where my family came from. That's something I want to
        share with you.
        <br />
        <br />
        We were immigrants to the UK and it was a real struggle for my parents.
        I know they tried their best but life was definitely challenging; it was
        hard to make ends meet. It was hard knowing that you couldn't afford
        much and taking on the stress of the adults in your life.
        <br /> <br />
        <Polaroid
          caption={"Baby :)"}
          image={baby}
          onClick={() => {}}
          active
          width="40%"
        />
        <br />
        <br />
        I remember throwing away school trip letters knowing they were
        unaffordable or translating official documents from the age of 10. In
        particular, I sorted out my family's immigration status at the age of 17
        - something they'd have gone to a lawyer for.
        <br />
        <br />
        That being said, life wasn't all doom and gloom! There were many happy
        and amazing moments too! My parents were incredibly loving and we always
        had food {"&"} shelter (something not everyone can say).
        <br />
        <br />
        I think it's important to remember that whilst things were hard, I had a
        great childhood. I'm keen to represent a balanced narrative to counter
        the one sided sob stories you sometimes hear about, so here are some
        happy photos:
        <br />
        <br />
        <Polaroid
          caption={"Snow day '03"}
          image={happy1}
          onClick={() => {}}
          width="60%"
          active
        />
        <Polaroid
          caption={"Suave little man"}
          image={happy2}
          onClick={() => {}}
          width="40%"
          active
        />
      </div>
    </div>
  )
}
