import "./App.css"
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useRoutes,
} from "react-router-dom"
import Homepage from "./pages/homepage"
import { AnimatePresence } from "framer-motion"
import { cloneElement } from "react"
import Loading from "./pages/loading"

// TODO - 404 page

function App() {
  const paths = useRoutes([
    { path: "/", element: <Loading /> },
    { path: "/home", element: <Homepage /> },
    { path: "*", element: 404 },
  ])

  const location = useLocation()

  if (!paths) return null

  return (
    <AnimatePresence mode="wait" initial={false}>
      {cloneElement(paths, { key: location.pathname })}
    </AnimatePresence>
  )
}

export default App
