import { PageTemplate } from "../types/page-templates"
import im from "../../src/assets/homepage/dance.png"
import dance from "../../src/assets/hobbies/dance.png"
import debating from "../../src/assets/hobbies/debating.png"
import theatre from "../../src/assets/hobbies/theatre.png"
import hobbies from "../../src/assets/hobbies/hobbies.png"
import Debate from "../pages/debate/debate"
import catwalk from "../../src/assets/transformation/catwalk.png"
import depression from "../../src/assets/transformation/depression.png"
import robot from "../../src/assets/transformation/robot.png"
import transformation from "../../src/assets/transformation/transformation.png"
import loves from "../../src/assets/loves/loves.png"
import coaching from "../../src/assets/loves/coaching.png"
import mathematics from "../../src/assets/loves/mathematics.png"
import physics from "../../src/assets/loves/physics.png"
import humanity from "../../src/assets/loves/humanity.png"
import time from "../../src/assets/time/time.png"
import past from "../../src/assets/time/past.png"
import future from "../../src/assets/time/future.png"
import Mathematics from "../pages/loves/mathematics"
import People from "../pages/loves/people"
import Languages from "../pages/hobbies/languages"
import Dance from "../pages/hobbies/dance"
import Improv from "../pages/hobbies/improv"
import OM from "../pages/transformation/om"
import LogicEmotion from "../pages/transformation/logicemotion"
import Depression from "../pages/transformation/depression"
import Past from "../pages/time/past"
import Future from "../pages/time/future"
import Present from "../pages/time/present"

export default [
  {
    title: "Loves",
    image_url: loves,
    pageSections: [
      {
        caption: "Mathematics",
        title: "Mathematics",
        image_url: mathematics,
        content: <Mathematics />,
      },

      {
        caption: "For the love of people",
        title: "People",
        image_url: humanity,
        content: <People />,
      },
    ],
  },

  {
    title: "Time",
    image_url: time,
    pageSections: [
      {
        caption: "The Past",
        title: "Past",
        image_url: past,
        content: <Past />,
      },
      {
        caption: "The Present",
        heading: "Present",
        image_url: time,
        content: <Present />,
      },
      {
        caption: "The Future",
        title: "Future",
        image_url: future,
        content: <Future />,
      },
    ],
  },
  {
    title: "Transformation",
    image_url: transformation,
    pageSections: [
      {
        caption: "15/07/2016",
        title: "Operation Madness",
        image_url: catwalk,
        content: <OM />,
      },
      {
        caption: "Logic vs Emotion",
        heading: "Logic and Emotion",
        image_url: robot,
        content: <LogicEmotion />,
      },
      {
        caption: "05/11/2020",
        title: "Depression",
        image_url: depression,
        content: <Depression />,
      },
    ],
  },
  {
    title: "Hobbies",
    image_url: hobbies,
    pageSections: [
      {
        caption: "05/10/2016",
        title: "Improv Theatre",
        image_url: theatre,
        content: <Improv />,
      },
      {
        caption: "13/08/2021",
        heading: "Dance",
        image_url: dance,
        content: <Dance />,
      },
      {
        caption: "05/09/2022",
        title: "Languages",
        image_url:
          "https://cdn.midjourney.com/e2b4cb41-6029-4d35-8d56-762dac2367f9/0_2.png",
        content: <Languages />,
      },
    ],
  },
] as PageTemplate[]
