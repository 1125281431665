import Polaroid, { PolaroidProps } from "./polaroid"
import "./styles.css"

export type PolaroidGroupProps = {
  polaroids: PolaroidProps[]
}

export default function PolaroidGroup(props: PolaroidGroupProps) {
  return (
    <div className="information-wrapper">
      {props.polaroids.map((i) => {
        return (
          <Polaroid
            image={i.image}
            caption={i.caption}
            onClick={i.onClick}
            width={i.width}
            fontSize={i.fontSize}
          />
        )
      })}
    </div>
  )
}
