import Polaroid from "../../components/polaroid/polaroid"
import nice from "../../assets/hobbies/nice.jpg"
import spain from "../../assets/hobbies/spain.jpg"
import rebeccaandpierre from "../../assets/hobbies/rebeccaandpierre.jpg"

export default function Languages() {
  return (
    <div className="information-content">
      <div className="information-content-title">
        Languages: Lost in translation
      </div>
      <div className="information-content-text">
        One of my favourite ways of connecting with someone is speaking to them
        in their own language. You get a different feel for the person - the
        jokes that otherwise wouldn't translate or a side of someone's
        personality that isn't expressed when they speak English.
        <br /> <br />
        <Polaroid
          active={true}
          image={nice}
          caption={`First days in Nice`}
          width="70%"
          onClick={function (): void {
            throw new Error("Function not implemented.")
          }}
        />
        <br /> <br />
        05/09/2022 was the day I moved to France for several months. I actually
        managed to picked up some decent French to boot!
        <br /> <br />
        <Polaroid
          active={true}
          image={rebeccaandpierre}
          caption={`Rebecca & Pierre 3am Swim`}
          width="70%"
          onClick={function (): void {
            throw new Error("Function not implemented.")
          }}
        />
        <br /> <br />
        Another thing I love about going to new places without any previous
        connections is the friends you can make along the way. Or, perhaps, the
        stupid things you'll do - like swimming at 3AM in the morning
        <br /> <br />
        <Polaroid
          active={true}
          image={spain}
          caption={`Moustache dinner party in Spain`}
          width="70%"
          onClick={function (): void {
            throw new Error("Function not implemented.")
          }}
        />
        <br /> <br />
        Or perhaps it's agreeing to grow a moustache because you were dared to
        do it!
        <br /> <br />
      </div>
    </div>
  )
}
