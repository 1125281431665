import React, { Children } from "react"
import Modal from "react-modal"

export type CustomisedModalProps = {
  isModalOpen: boolean
  setModalClosed: () => void
  children: React.ReactNode
}

export default function CustomisedModal(props: CustomisedModalProps) {
  return (
    <Modal
      isOpen={props.isModalOpen}
      onRequestClose={props.setModalClosed}
      overlayClassName="modal-overlay"
    >
      <button
        onClick={props.setModalClosed}
        aria-label="close"
        className="x"
        style={{ fontSize: "2rem" }}
      >
        ❌
      </button>
      {props.children}
    </Modal>
  )
}
