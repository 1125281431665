export default function Mathematics() {
  return (
    <div className="information-content">
      <div className="information-content-title">My love of Mathematics</div>
      <div className="information-content-text">
        They do say that you never forget your first love. For me, my first true
        love was definitely the world of mathematics. I remember spending hours
        and a small treehouse's worth of paper trying to derive a general cubic
        formula. For those that don't remember, this is the quadratic formula
        that we all learned in school:
        <br />
        <br />
        <img
          className="information-content-image"
          src="https://upload.wikimedia.org/wikipedia/commons/c/c4/Quadratic_formula.svg"
        />
        For context, this is what the solution to the cubic formula looks like:
        <br />
        <br />
        <img
          className="information-content-image"
          src="https://math.vanderbilt.edu/schectex/courses/cubic/cubic.gif"
        />
        14 year old me stood no chance.
        <br />
        <br />
        I love mathematics to this day and I’ve honestly never seen anything
        more beautiful. I feel like it gives an insight into the fabric of logic
        that can only be appreciated by understanding the detail of a proof. It
        delves into the fundamental question of why.
        <br />
        <br />
        One of my favourite mathematical stories involves some of the equations
        I stated above. There exist full generalised solutions to the quadratic
        equation (2), cubic equation (3) and the quartic equations (4)… but then
        it stops at quintic (5).
        <br />
        <br />
        To answer why this is the case, we look to the life of a young Evariste
        Galois (after whom the eponymous Galois theory is named). He was by all
        definitions of the word, a prodigy, and almost single handedly created a
        new branch of mathematics … until of course, he agrees to duel for the
        love of a woman.
        <br /> <br /> The night before he and his brother scribbled down his
        mathematics so that it may be preserved - within which, we can see that
        it is impossible to have a quintic formula. Galois, of course, died.
        Mathematicians are not known for their flirting or duelling skills. He
        did leave us with something much more - one of the most beautiful areas
        of mathematics (according to one guy).
        <br />
        <br />
        <img
          className="information-content-image"
          src="https://www.bbvaopenmind.com/wp-content/uploads/2016/05/bbva-openmind-materia-3b-Galois-1-1.jpg"
        />
        Unfortunately, I did not have such a fate to die with honour in the name
        of Mathematics. Instead, all I have to show is a 1st from the University
        in Warwick.
        <br />
        <br />
      </div>
    </div>
  )
}
