import "./styles.css"

export type PolaroidProps = {
  caption: string
  image: string
  onClick: () => void
  width?: string
  active?: boolean
  fontSize?: string
}

export default function Polaroid(props: PolaroidProps) {
  return (
    <div className="wrapper">
      <div
        className={`item ${props.active ? "active" : ""}`}
        onClick={(e) => {
          if (props.onClick) props.onClick()
        }}
        style={{ width: props.width }}
      >
        <div className="polaroid">
          <img src={props.image} />
          <div className="caption" style={{ fontSize: props.fontSize }}>
            {props.caption}
          </div>
        </div>
      </div>
    </div>
  )
}
